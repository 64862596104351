import {registerInitializer} from "../shared/utils";

export const initializeNavigationSlider = () => {
  $('.anchor-link').on('click', function(event) {
    event.preventDefault();
    var element = $(this).attr("href");
    $(document).scrollTo($(element).offset().top - 140, 500);
  });
};

registerInitializer(initializeNavigationSlider);
